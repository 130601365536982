import React from "react";
import styled, { keyframes } from "styled-components";
import Logo from "./media/Logo.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PageWrapper = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: sans-serif;
  color: #fff;
`;

const ContentWrapper = styled.div`
  text-align: center;

  animation: ${fadeIn} 1s ease-in-out forwards;
`;

// const CompanyName = styled.h1`
//   font-size: 3rem;
//   margin-bottom: 2rem;

//   @media (max-width: 768px) {
//     font-size: 2rem;
//     margin-bottom: 1rem;
//   }
// `;

const Description = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: ${fadeIn} 1s ease-in-out forwards;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const LaunchDate = styled.p`
  font-size: 1rem;
  color: gray;
  opacity: 0.5;
  animation: ${fadeIn} 1s ease-in-out forwards;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  max-width: 100%;
  height: auto;
`;

function ComingSoonPage() {
  return (
    <PageWrapper>
      <ContentWrapper>
        <LogoContainer>
          <LogoImg src={Logo} />
        </LogoContainer>
        <Description>The Revolution Of Cyber Security Training</Description>
        <LaunchDate>Launching 2023</LaunchDate>
      </ContentWrapper>
    </PageWrapper>
  );
}

export default ComingSoonPage;
